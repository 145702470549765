import React from 'react';
import PropTypes from 'prop-types';
import { colorWhite } from 'src/styles/exports.module.scss';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { noteCard_ } from './NoteCard.module.scss';
import { FluidText } from '../FluidText';
import { Background } from '../Background';

const NoteCard = ({ heading, body, borderColor }) => {
  const headingContent = typeof heading === 'string' ? (
    <FluidText type="h3" all="ws-eyebrow-xl">
      {heading}
    </FluidText>
  ) : heading;

  const bodyContent = typeof body === 'string' ? (
    <FluidText
      type="markdown"
      min="ws-text-lg"
      max="ws-text-2xl"
      markdownProps={{
        mode: MARKDOWN_MODES.inlineText,
      }}
    >
      {body}
    </FluidText>
  ) : body;

  return (
    <Background className={noteCard_} background={colorWhite} style={{ borderColor }}>
      {headingContent}
      {bodyContent}
    </Background>
  );
};

NoteCard.propTypes = {
  heading: PropTypes.node,
  body: PropTypes.node.isRequired,
  borderColor: PropTypes.string,
};

export { NoteCard };
