import React from 'react';
import { toFieldPath } from '@stackbit/annotations';
import PropTypes from 'prop-types';
import { getMediaPropsFromGatsbyContentfulMedia, getMediaPropsFromContentfulMedia } from 'src/utils/contentful';
import { SplitHero } from 'src/components/subsections/SplitHero';
import { Media } from 'src/tapestry/core/media';
import { ProductHeroMedia } from 'src/components/common/ProductHeroMedia';
import { FluidText } from 'src/components/common/FluidText';
import { ContentfulCTA } from 'src/components/common/ContentfulCTA';
import { useStringWithGlobals } from 'src/components/contexts/TranslationContext';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { withFramework } from 'lib/get-framework';

// These are the values from the `heroVariant` field in Contentful
export const HERO_VARIANTS = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
  DEFAULT: 'dynamic',
};

export const ContentfulHero = ({
  heroHeading,
  heroBody,
  heroAsset,
  heroBackgroundColor,
  heroEyebrow,
  primaryCta,
  heroSecondaryCtaLabel,
  heroSecondaryCtaUrl,
  contentful_id,
  heroVariant: providedHeroVariant = HERO_VARIANTS.DEFAULT,
  ...subsectionProps
}) => {
  const heroVariant = Object.values(HERO_VARIANTS).includes(providedHeroVariant) ? providedHeroVariant : HERO_VARIANTS.DEFAULT;
  const hasSecondaryCta = !!heroSecondaryCtaLabel && !!heroSecondaryCtaUrl;

  const VARIANT_PROPS = {
    [HERO_VARIANTS.STATIC]: {
      mediaComponent: Media,
      splitHeroProps: {
        isFullHeight: true,
      },
      mediaProps: {
        releaseAspectRatio: true,
        objectFit: 'contain',
        lazyload: false,
      },
    },
    [HERO_VARIANTS.DYNAMIC]: {
      mediaComponent: ProductHeroMedia,
      splitHeroProps: {},
      mediaProps: {
        backgroundColor: heroBackgroundColor,
      },
    }
  };

  const { splitHeroProps: splitHeroVariantProps,
    mediaComponent: MediaComponent,
    mediaProps: mediaVariantProps } = VARIANT_PROPS[heroVariant];

  return (
    <SplitHero
      {...subsectionProps}
      eyebrow={useStringWithGlobals(heroEyebrow)}
      heading={
        <FluidText type="h1" {...toFieldPath('heroHeading')}>
          {useStringWithGlobals(heroHeading)}
        </FluidText>
      }
      subheading={
        <FluidText type="markdown" markdownProps={{ mode: MARKDOWN_MODES.inlineText }} {...toFieldPath('heroBody')}>
          {useStringWithGlobals(heroBody)}
        </FluidText>
      }
      media={
        heroAsset && (
          <MediaComponent
            {...withFramework({
              gatsby: () => getMediaPropsFromGatsbyContentfulMedia(heroAsset),
              next: () => getMediaPropsFromContentfulMedia(heroAsset)
            })}
            {...mediaVariantProps}
          />
        )
      }
      cta={primaryCta}
      secondaryCta={
        hasSecondaryCta && (
          <ContentfulCTA
            type="link"
            variant="secondary"
            size="xxl"
            label={heroSecondaryCtaLabel}
            link={heroSecondaryCtaUrl}
            labelFieldPath={toFieldPath({
              objectId: contentful_id,
              fieldPath: 'heroSecondaryCtaLabel',
            })}
            {...toFieldPath({ objectId: contentful_id, fieldPath: 'heroSecondaryCtaUrl' })}
          />
        )
      }
      {...splitHeroVariantProps}
    />
  );
};

ContentfulHero.propTypes = {
  heroHeading: PropTypes.string.isRequired,
  heroBody: PropTypes.string,
  heroAsset: PropTypes.shape({}),
  heroBackgroundColor: PropTypes.string,
  heroEyebrow: PropTypes.node,
  primaryCta: PropTypes.node,
  heroSecondaryCtaLabel: PropTypes.string,
  heroSecondaryCtaUrl: PropTypes.string,
  contentful_id: PropTypes.string,
  heroVariant: PropTypes.oneOf(Object.values(HERO_VARIANTS)),
};
