import React from 'react';
import { withFramework } from 'lib/get-framework';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { FeatureCardGrid } from 'src/components/subsections/FeatureCardGrid';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { ContentfulFeatureCard } from './components';
import { getCardConfigObject } from './ContentfulFeatureCardSection.config';

export const ContentfulFeatureCardSection = ({
  sectionHeading = {},
  cardLayout,
  featureCards: _featureCards,
  ...subsectionProps
}) => {
  const { heading, subheading } = sectionHeading;
  const { getStringWithGlobals } = useTranslationContext();
  const headingWithGlobals = getStringWithGlobals(heading);
  const subheadingWithGlobals = getStringWithGlobals(subheading);
  const featureCards = withFramework({
    gatsby: () => _featureCards,
    next: () => _featureCards.items,
  });
  const featureCardCount = featureCards?.length;
  const hasFeatureCards = featureCardCount > 0;

  const cardsConfig = getCardConfigObject({ featureCardCount, layout: cardLayout });

  return (
    <>
      {heading && (
      <SectionHeading
        heading={headingWithGlobals}
        subheading={subheadingWithGlobals}
        textAlign="left"
        {...subsectionProps}
        addBottomSpacing
      />
      )}
      {hasFeatureCards && (
        <FeatureCardGrid
          {...subsectionProps}
          cards={featureCards.map(({ asset, ...props }, index) => {
            return (<ContentfulFeatureCard
              key={props.contentfulId}
              asset={asset}
              reduceCardAssetSize={!cardsConfig.includeCardAssetFull[index] && !!asset}
              cellWidths={cardsConfig.cardWidths[index]}
              variant={cardsConfig.cardVariants[index]}
              {...props}
            />);
          })}
        />)}
    </>);
};
