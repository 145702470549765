import React from 'react';
import PropTypes from 'prop-types';
import { MARKDOWN_MODES, Markdown } from 'src/tapestry/core/markdown';
import { asSubsection } from 'src/components/layout';
import { FluidText } from 'src/components/common/FluidText';
import { toFieldPath } from '@stackbit/annotations';
import { Table, TableRow, TableCell } from './components';
import { headingNote_, footnote_ } from './ComparisonTable.module.scss';

const MARKDOWN_TABLE_RENDERERS = {
  table: Table,
  tableRow: TableRow,
  tableCell: TableCell,
  // tableHead (<thead>) and tableBody (<tbody>) are also supported, if we ever
  // needed to customize those in the future.
};

const NON_TABLE_MARKDOWN_ELEMENTS = [
  'break',
  'paragraph',
  'thematicBreak',
  'blockquote',
  'delete',
  'image',
  'imageReference',
  'list',
  'listItem',
  'definition',
  'heading',
  'code',
  'html',
];

const COLUMN_HEADER_RENDERERS = {
  inlineCode: ({ children }) => (
    <FluidText type="span" all="ws-text-md" className={headingNote_}>
      {children}
    </FluidText>
  ),
};

export const ComparisonTable = asSubsection('ComparisonTable')(
  ({ data, markdownData, className, footnote, ...rest }) => {
    return (
      <div className={className} {...rest}>
        {markdownData ? (
          <div {...toFieldPath({ fieldPath: 'table' })}>
            <Markdown
              source={markdownData}
              renderers={{ ...MARKDOWN_TABLE_RENDERERS, ...COLUMN_HEADER_RENDERERS }}
              disallowedTypes={NON_TABLE_MARKDOWN_ELEMENTS}
            />
          </div>
        ) : (
          <Table>
            <thead>
              <TableRow isHeader>
                {data.headings.map(heading => (
                  <TableCell key={heading}>
                    <Markdown source={heading} renderers={COLUMN_HEADER_RENDERERS} />
                  </TableCell>
                ))}
              </TableRow>
            </thead>
            <tbody>
              {data.rows.map(row => (
                <TableRow key={row.header}>
                  <TableCell>{row.header}</TableCell>
                  {row.cells.map((cell, index) => (
                    <TableCell key={`${row.header}-cell-${index + 1}`} annotation={row?.cellAnnotations?.[index]}>
                      <Markdown source={cell} unwrapElements="paragraph" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </Table>
        )}
        {footnote && (
          <footer>
            <FluidText
              className={footnote_}
              type="markdown"
              all="ws-text-sm"
              markdownProps={{
                mode: MARKDOWN_MODES.inlineText,
              }}
              {...toFieldPath({ fieldPath: 'tableFootnote' })}
            >
              {footnote}
            </FluidText>
          </footer>
        )}
      </div>
    );
  }
);

ComparisonTable.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        cells: PropTypes.arrayOf(PropTypes.string),
        cellAnnotations: PropTypes.arrayOf(PropTypes.shape({})),
      })
    ),
  }),
  markdownData: PropTypes.string,
  footnote: PropTypes.string,
};
