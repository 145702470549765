export const FEATURE_CARD_SECTION_LAYOUT_OPTIONS = {
  LAYOUT_1: 'Layout 1',
  LAYOUT_2: 'Layout 2',
};

// Base card configurations
const SMALL_CARD_CONFIG = { lg: { width: 6, height: 1 } };
const LARGE_CARD_CONFIG = { lg: { width: 6, height: 2 } };
const FULL_WIDTH_CARD_CONFIG = { lg: { width: 12, height: 1 } };

// Row configurations
const FIRST_ROW_CONFIG = { lg: { top: 1 } };
const SECOND_ROW_CONFIG = { lg: { top: 2 } };
const THIRD_ROW_CONFIG = { lg: { top: 3 } };

// Column configurations
const RIGHT_COLUMN_CONFIG = { lg: { left: 7 } };

export const combineCardConfigs = (...configObjects) => {
  const combinedKeys = configObjects.reduce((acc, config) => [...acc, ...Object.keys(config)], []);
  return combinedKeys.reduce((acc, key) => ({
    ...acc,
    [key]: {
      ...configObjects.reduce((innerAcc, config) => ({ ...innerAcc, ...config[key] }), {}),
    },
  }), {});
};

export const getCardConfigObject = ({ featureCardCount, layout }) => {
  const configOptions = {
    /*
    Layout 1
    */
    [FEATURE_CARD_SECTION_LAYOUT_OPTIONS.LAYOUT_1]:
  { cardWidths: [
    // Card 1
    combineCardConfigs(LARGE_CARD_CONFIG),
    // Card 2
    combineCardConfigs(
      featureCardCount <= 2 ? LARGE_CARD_CONFIG : SMALL_CARD_CONFIG,
      FIRST_ROW_CONFIG,
      RIGHT_COLUMN_CONFIG),
    // Card 3
    combineCardConfigs(
      SMALL_CARD_CONFIG,
      featureCardCount <= 3 ? SECOND_ROW_CONFIG : THIRD_ROW_CONFIG,
      featureCardCount <= 3 && RIGHT_COLUMN_CONFIG,
    ),
    // Card 4
    combineCardConfigs(
      LARGE_CARD_CONFIG,
      SECOND_ROW_CONFIG,
      RIGHT_COLUMN_CONFIG
    ),
    // Card 5
    FULL_WIDTH_CARD_CONFIG
  ],
  cardVariants: [undefined, undefined, undefined, undefined, 'wideHorizontalSplit'],
  includeCardAssetFull: [true, featureCardCount <= 2, false, true, true] },
    /*
    Layout 2
    */
    [FEATURE_CARD_SECTION_LAYOUT_OPTIONS.LAYOUT_2]:
  { cardWidths: [
    // Card 1
    featureCardCount <= 2 ? LARGE_CARD_CONFIG : SMALL_CARD_CONFIG,
    // Card 2
    combineCardConfigs(
      LARGE_CARD_CONFIG,
      RIGHT_COLUMN_CONFIG,
    ),
    // Card 3
    combineCardConfigs(
      featureCardCount >= 4 ? LARGE_CARD_CONFIG : SMALL_CARD_CONFIG,
      SECOND_ROW_CONFIG
    ),
    // Card 4
    combineCardConfigs(
      SMALL_CARD_CONFIG,
      RIGHT_COLUMN_CONFIG,
      THIRD_ROW_CONFIG
    ),
    // Card 5
    FULL_WIDTH_CARD_CONFIG,
  ],
  cardVariants: [undefined, undefined, undefined, undefined, 'wideHorizontalSplit'],
  includeCardAssetFull: [featureCardCount <= 2, true, featureCardCount >= 4, false, true] },
  };

  return configOptions?.[layout] ?? configOptions[FEATURE_CARD_SECTION_LAYOUT_OPTIONS.LAYOUT_1];
};
