import getFrameworkEnvVar from 'lib/get-framework-env-var';

export const MORTGAGE_RATES_ENDPOINT = `${process.env.NODE_ENV === 'test' ? getFrameworkEnvVar('BASE_URL') : ''}/api/mortgage-rates`;

export async function fetchMortgageRates() {
  const response = await fetch(MORTGAGE_RATES_ENDPOINT);
  if (!response.ok) {
    throw new Error(`Unable to retrieve mortgage rates. Status: ${response.status}`);
  }

  return response.json();
}
