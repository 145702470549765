export const translations = {
  'en-ca': {
    'contentful-logo-showcase::logo-alt-text::osler-logo': 'Osler logo',
    'contentful-logo-showcase::logo-alt-text::loopio-logo': 'Loopio logo',
    'contentful-logo-showcase::logo-alt-text::achievers-logo': 'Achievers logo',
    'contentful-logo-showcase::logo-alt-text::league-logo': 'League logo',
    'contentful-logo-showcase::logo-alt-text::knix-logo': 'Knix logo',
  },
  'fr-ca': {
    'contentful-logo-showcase::logo-alt-text::osler-logo': 'Logo Osler',
    'contentful-logo-showcase::logo-alt-text::loopio-logo': 'Logo Loopio',
    'contentful-logo-showcase::logo-alt-text::achievers-logo': 'Logo Achievers',
    'contentful-logo-showcase::logo-alt-text::league-logo': 'Logo League',
    'contentful-logo-showcase::logo-alt-text::knix-logo': 'Logo Knix',
  },
};
