import { colorNavyBg, colorMidnightBg, colorLinenBg, colorLinenLightBg,
  colorGrassLight, colorGrassBase, colorChartreuse95, colorCeleryBg90,
  colorSandLight, colorSandBase, colorBlushLight, colorBlushBase,
  colorOceanLight, colorOceanBase, colorTerracotta95, colorTerracottaBg90,
  colorApricot95, colorApricotBg90, colorAubergine95, colorAubergineBg90,
  colorLilac95, colorLilacBg90, colorSky95, colorSkyBg90,
  colorChartreuseBg90, colorSlate95, colorCelery95, colorBlack } from 'src/styles/exports.module.scss';

export const HEX_COLOR_MAP = {
  [colorNavyBg]: { label: 'Generation/Navy', secondaryColor: colorMidnightBg },
  [colorMidnightBg]: { label: 'Generation/Midnight', secondaryColor: colorNavyBg },
  [colorLinenLightBg]: { label: 'Premium/LightLinen', secondaryColor: colorLinenBg },
  [colorLinenBg]: { label: 'Premium/Linen', secondaryColor: colorLinenLightBg },
  [colorGrassLight]: { label: 'Grass/Light', secondaryColor: colorChartreuse95 },
  [colorGrassBase]: { label: 'Grass/Medium', secondaryColor: colorCeleryBg90 },
  [colorSandLight]: { label: 'Sand/Light', secondaryColor: colorLinenBg },
  [colorSandBase]: { label: 'Sand/Medium', secondaryColor: colorLinenBg },
  [colorBlushLight]: { label: 'Blush/Light', secondaryColor: colorBlushBase },
  [colorBlushBase]: { label: 'Blush/Medium', secondaryColor: colorBlushLight },
  [colorOceanLight]: { label: 'Ocean/Light', secondaryColor: colorSky95 },
  [colorOceanBase]: { label: 'Ocean/Medium', secondaryColor: colorOceanLight },
  [colorTerracotta95]: { label: 'Terracotta/Foreground/95', secondaryColor: colorTerracottaBg90 },
  [colorTerracottaBg90]: { label: 'Terracotta/Background/90', secondaryColor: colorTerracotta95 },
  [colorApricot95]: { label: 'Apricot/Foreground/95', secondaryColor: colorApricotBg90 },
  [colorApricotBg90]: { label: 'Apricot/Background/90', secondaryColor: colorApricot95 },
  [colorAubergine95]: { label: 'Aubergine/Foreground/95', secondaryColor: colorAubergineBg90 },
  [colorAubergineBg90]: { label: 'Aubergine/Background/90', secondaryColor: colorAubergine95 },
  [colorLilac95]: { label: 'Lilac/Foreground/95', secondaryColor: colorLilacBg90 },
  [colorLilacBg90]: { label: 'Lilac/Background/90', secondaryColor: colorLilac95 },
  [colorSky95]: { label: 'Sky/Foreground/95', secondaryColor: colorSkyBg90 },
  [colorSkyBg90]: { label: 'Sky/Background/90', secondaryColor: colorSky95 },
  [colorChartreuse95]: { label: 'Chartreuse/Foreground/95', secondaryColor: colorChartreuseBg90 },
  [colorChartreuseBg90]: { label: 'Chartreuse/Background/90', secondaryColor: colorCelery95 },
  [colorSlate95]: { label: 'Slate/95', secondaryColor: colorLinenLightBg },
  [colorBlack]: { label: 'SoftBlack', value: '#32302F', secondaryColor: colorMidnightBg },
};

export const getColorInfo = (color, colorMap = HEX_COLOR_MAP) => {
  if (typeof color === 'string') { return colorMap[color.toLowerCase()] ?? {}; }
  return {};
};
