import React from 'react';
import PropTypes from 'prop-types';
import { toFieldPath, toObjectId } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { useInView } from 'react-intersection-observer';
import { WATERFALL_ANIMATION_DELAY } from 'src/components/subsections/FeatureBlock';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { FluidText } from 'src/components/common/FluidText';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { listWrapper_, listHeading_ } from './FeatureBlockStatsList.module.scss';

const FeatureBlockStatsList = ({ className = '', list = [], inViewThreshold = 0.2, reverseWaterfallAnimation = false }) => {
  const [viewRef, inView] = useInView({ triggerOnce: true, threshold: inViewThreshold });
  const { prefersReducedMotion } = useMotionOptOutContext();

  return (
    <div
      ref={viewRef}
      className={classNames(
        className,
        inView && 'waterfall-trigger',
        reverseWaterfallAnimation && !prefersReducedMotion && 'waterfall-transition'
      )}
    >
      {list.map(({ heading, details, entryId }, index) => {
        const renderHeadingAnnotation = entryId
          ? toFieldPath({ objectId: entryId, fieldPath: 'itemHeading' })
          : {};
        const renderMarkdownAnnotation = entryId
          ? toFieldPath({ objectId: entryId, fieldPath: 'itemBody' })
          : {};

        return (
          <div
            key={entryId || heading}
            className={classNames('waterfall-element', listWrapper_)}
            style={{ transitionDelay: `${WATERFALL_ANIMATION_DELAY * index}s` }}
            {...toObjectId(entryId)}
          >
            <FluidText
              type="h3"
              min="ws-display-sm"
              max="ws-display-lg"
              className={listHeading_}
              {...renderHeadingAnnotation}
            >
              {heading}
            </FluidText>
            <FluidText
              type="markdown"
              markdownProps={{ mode: MARKDOWN_MODES.textWithLists }}
              {...renderMarkdownAnnotation}
            >
              {details}
            </FluidText>
          </div>
        );
      })}
    </div>
  );
};

FeatureBlockStatsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      details: PropTypes.string,
      entryId: PropTypes.string,
    })
  ),
  inViewThreshold: PropTypes.number,
  reverseWaterfallAnimation: PropTypes.bool,
};

export { FeatureBlockStatsList };
