import React from 'react';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { ComparisonTable } from 'src/components/subsections/ComparisonTable';
import { useTranslationContext } from '../contexts/TranslationContext';

export const ContentfulComparisonTable = ({
  sectionHeading,
  table,
  tableFootnote,
  ...subsectionProps
}) => {
  const { heading, subheading } = sectionHeading;
  const { getStringWithGlobals } = useTranslationContext();

  const tableProps = {
    footnote: getStringWithGlobals(tableFootnote?.tableFootnote),
    markdownData: getStringWithGlobals(table?.table),
  };

  return (
    <>
      {sectionHeading && (
        <SectionHeading
          heading={getStringWithGlobals(heading)}
          subheading={getStringWithGlobals(subheading)}
          textAlign="left"
          {...subsectionProps}
          addBottomSpacing
        />
      )}
      <ComparisonTable {...subsectionProps} {...tableProps} />
    </>
  );
};
