import React, { useCallback } from 'react';
import { FeatureCard } from 'src/components/common/FeatureCard';
import { toObjectId, toFieldPath } from '@stackbit/annotations';
import { Media } from 'src/tapestry/core/media';
import { getMediaPropsFromContentfulMedia, getMediaPropsFromGatsbyContentfulMedia } from 'src/utils/contentful';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { generateXPathSelectorString } from 'src/utils/netlify-create/xpath';
import { TagPill } from 'src/components/common/TagPill';
import { getColorInfo } from 'src/utils/colors/color-mapping';
import { withFramework } from 'lib/get-framework';

export const ContentfulFeatureCard = ({
  itemHeading,
  itemBody,
  itemEyebrow,
  backgroundColor,
  asset,
  reduceCardAssetSize,
  ctaUrl,
  variant,
  style,
  contentfulId,
}) => {
  const { getStringWithGlobals } = useTranslationContext();
  const itemHeadingWithGlobals = getStringWithGlobals(itemHeading);
  const itemBodyWithGlobals = getStringWithGlobals(withFramework({
    gatsby: () => itemBody?.itemBody,
    next: () => itemBody,
  }));

  const mediaAlign = style?.backgroundColor?.alignItems;
  const { secondaryColor: eyebrowColor } = getColorInfo(backgroundColor);

  const mediaProps = asset ? withFramework({
    gatsby: () => getMediaPropsFromGatsbyContentfulMedia(asset),
    next: () => getMediaPropsFromContentfulMedia(asset),
  }) : {};

  const shouldDisplayAsset = useCallback(() => {
    if (!asset) {
      return false;
    }

    return reduceCardAssetSize ? mediaProps.aspectRatio >= 2 : true;
  }, [reduceCardAssetSize, asset, mediaProps.aspectRatio]);

  return (<FeatureCard
    {...toObjectId(contentfulId)}
    {...toFieldPath(
      { fieldPath: 'backgroundColor', xpath: generateXPathSelectorString('FeatureCard_background') },
      { fieldPath: 'itemHeading', xpath: generateXPathSelectorString('FeatureCard_heading') },
      { fieldPath: 'itemBody', xpath: generateXPathSelectorString('FeatureCard_subheading') },
      { fieldPath: 'ctaUrl', xpath: generateXPathSelectorString('ArrowLinkButton_href') },
    )}
    preContent={itemEyebrow &&
      <TagPill variant="xl" className="ws-text-md-medium" background={eyebrowColor}>
        <p {...toFieldPath('itemEyebrow')}>{itemEyebrow}</p>
      </TagPill>}
    addBorder={!backgroundColor}
    heading={itemHeadingWithGlobals}
    details={itemBodyWithGlobals}
    background={backgroundColor}
    media={shouldDisplayAsset() && <Media {...mediaProps} {...toFieldPath('backgroundColor')} />}
    href={ctaUrl}
    customControls
    mediaAlign={mediaAlign}
    variant={variant}
  />);
};
