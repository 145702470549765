import React from 'react';
import PropTypes from 'prop-types';
import { REFRESH_BREAKPOINTS, generateFluidClampString } from 'src/utils/css';

const DEFAULT_BREAKPOINTS = {
  WIDTH: [REFRESH_BREAKPOINTS.sm, REFRESH_BREAKPOINTS.xl],
  HEIGHT: [480, 1080],
};

export const FluidContainer = ({
  ref,
  className,
  minWidthPx,
  maxWidthPx,
  minHeightPx,
  maxHeightPx,
  minWidthBreakpoint = DEFAULT_BREAKPOINTS.WIDTH[0],
  maxWidthBreakpoint = DEFAULT_BREAKPOINTS.WIDTH[1],
  minHeightBreakpoint = DEFAULT_BREAKPOINTS.HEIGHT[0],
  maxHeightBreakpoint = DEFAULT_BREAKPOINTS.HEIGHT[1],
  children,
  style,
  as = 'div',
  ...rest
}) => {
  const isFluidWidth = minWidthPx && maxWidthPx;
  const isFluidHeight = minHeightPx && maxHeightPx;
  const Wrapper = as;

  if (!isFluidWidth && !isFluidHeight) return children;

  let widthClampString;
  let heightClampString;

  if (isFluidWidth) {
    widthClampString = generateFluidClampString(
      minWidthPx,
      maxWidthPx,
      minWidthBreakpoint,
      maxWidthBreakpoint,
      true
    );
  }

  if (isFluidHeight) {
    heightClampString = generateFluidClampString(
      minHeightPx,
      maxHeightPx,
      minHeightBreakpoint,
      maxHeightBreakpoint,
      false
    );
  }

  return (
    <Wrapper
      ref={ref}
      className={className}
      style={{
        ...style,
        width: isFluidWidth && widthClampString,
        height: isFluidHeight && heightClampString,
      }}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

FluidContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  minWidthPx: PropTypes.number,
  maxWidthPx: PropTypes.number,
  minHeightPx: PropTypes.number,
  maxHeightPx: PropTypes.number,
  minWidthBreakpoint: PropTypes.number,
  maxWidthBreakpoint: PropTypes.number,
  minHeightBreakpoint: PropTypes.number,
  maxHeightBreakpoint: PropTypes.number,
  style: PropTypes.shape({}),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
