import React, { useMemo } from 'react';
import { normalizeProps } from 'src/utils/contentful';
import { ContentfulFeatureCardSection } from './ContentfulFeatureCardSection';
import { ContentfulKeyPointsHorizontal } from './ContentfulKeyPointsHorizontal';
import { ContentfulKeyPointsVertical } from './ContentfulKeyPointsVertical';
import { ContentfulMediaWithText } from './ContentfulMediaWithText.component';
import { ContentfulComparisonTable } from './ContentfulComparisonTable.component';
import { ContentfulSpecialContent } from './ContentfulSpecialContent';
import { ContentfulPromoBlock } from '../common/ContentfulPromoBlock';
import { ContentfulTierCards } from './ContentfulTierCards';
import { CONTENTFUL_SECTIONS } from './contentful.constants';

const { FEATURE_CARD, KEY_POINTS_VERTICAL, KEY_STATS, SPECIAL_CONTENT, PROMOTIONAL, TABLE, MEDIA_WITH_DESCRIPTION, TIER_CARDS } = CONTENTFUL_SECTIONS;

const MODEL_TO_COMPONENT_MAP = {
  [FEATURE_CARD]: ContentfulFeatureCardSection,
  [KEY_STATS]: ContentfulKeyPointsHorizontal,
  [KEY_POINTS_VERTICAL]: ContentfulKeyPointsVertical,
  [MEDIA_WITH_DESCRIPTION]: ContentfulMediaWithText,
  [TABLE]: ContentfulComparisonTable,
  [SPECIAL_CONTENT]: ContentfulSpecialContent,
  [PROMOTIONAL]: ContentfulPromoBlock,
  [TIER_CARDS]: ContentfulTierCards,
};

export const BodyContent = ({
  contentProps: { contentTypeId, ...contentProps } = {},
  ...subsectionProps
}) => {
  const normalizedContentProps = normalizeProps(contentProps, { contentTypeId });

  const BodyComponent = useMemo(
    () => MODEL_TO_COMPONENT_MAP[contentTypeId] || (() => <></>), // eslint-disable-line react/jsx-no-useless-fragment
    [contentTypeId]
  );

  return <BodyComponent {...subsectionProps} {...normalizedContentProps} />;
};
