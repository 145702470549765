import React from 'react';
import { FeatureBlock, FeatureBlockCopy, FeatureBlockMedia } from 'src/components/subsections/FeatureBlock';
import { Media } from 'src/tapestry/core/media';
import { getMediaPropsFromGatsbyContentfulMedia, getMediaPropsFromContentfulMedia } from 'src/utils/contentful';
import { FeatureBlockBody } from 'src/components/common/FeatureBlockBody';
import { toFieldPath } from '@stackbit/annotations';
import { withFramework } from 'lib/get-framework';
import { ContentfulCTA } from '../common/ContentfulCTA';
import { useTranslationContext } from '../contexts/TranslationContext';

export const ContentfulMediaWithText = ({
  sectionHeading = {},
  asset,
  cta,
  mediaCaption,
  mediaBackground,
  imageAlignment,
  mobileImageAlignment,
  ...subsectionProps
}) => {
  const { getStringWithGlobals } = useTranslationContext();

  const { heading, subheading, eyebrow, ...restSectionHeading } = sectionHeading;

  const mediaProps = withFramework({
    gatsby: () => getMediaPropsFromGatsbyContentfulMedia(asset),
    next: () => getMediaPropsFromContentfulMedia(asset)
  });
  const caption = withFramework({
    gatsby: () => mediaCaption?.mediaCaption,
    next: () => mediaCaption
  });

  return (
    <FeatureBlock
      {...subsectionProps}
      reverseOrderDesktop={imageAlignment === 'right'}
      reverseOrderMobile={mobileImageAlignment === 'belowText'}
      reverseWaterfallAnimation={false}
    >
      <FeatureBlockMedia {...toFieldPath('mediaBackground')}>
        {asset && (
          <Media
            {...mediaProps}
            caption={caption}
            roundCorners
            backgroundColor={mediaBackground}
          />
        )}
      </FeatureBlockMedia>
      <FeatureBlockCopy>
        <FeatureBlockBody
          heading={getStringWithGlobals(heading)}
          subheading={getStringWithGlobals(subheading)}
          eyebrow={getStringWithGlobals(eyebrow)}
          {...restSectionHeading}
          cta={cta && <ContentfulCTA variant="secondary" size="md" {...cta} />}
        />
      </FeatureBlockCopy>
    </FeatureBlock>
  );
};
