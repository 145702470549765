import React from 'react';
import { toFieldPath } from '@stackbit/annotations';
import { CallToActionBlock } from 'src/components/subsections/CallToActionBlock';
import { DEFAULT_CONFIG_BY_ELEMENT_TYPE, FluidText } from 'src/components/common/FluidText';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { useTranslationContext } from '../contexts/TranslationContext';

export const ContentfulFinalCta = ({ finalCtaHeading, finalCtaBody, primaryCta, hasBackgroundImage, ...subsectionProps }) => {
  const { getStringWithGlobals } = useTranslationContext();

  return (<CallToActionBlock
    {...subsectionProps}
    heading={
      <FluidText
        type="h2"
        {...DEFAULT_CONFIG_BY_ELEMENT_TYPE.h1}
        {...toFieldPath('finalCtaHeading')}
      >
        {getStringWithGlobals(finalCtaHeading)}
      </FluidText>
    }
    subheading={
      <FluidText type="markdown" markdownProps={{ mode: MARKDOWN_MODES.inlineText }} {...toFieldPath('finalCtaBody')}>
        {getStringWithGlobals(finalCtaBody)}
      </FluidText>
    }
    cta={primaryCta}
    hasBackgroundImage={hasBackgroundImage}
  />);
};
