import { DEFAULT_LOCALE, isEquivalentLocale } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { ACCOUNTS_WITHOUT_PAGES } from './constants';

/**
 * Returns the account link based on the account details queried from Contentful.
 *
 * @param {Object} account - The account object.
 * @param {string} account.urlSlug - The URL slug of the account.
 * @param {Object} account.accountPageOverride - The account page override object.
 * @param {string} account.accountPageOverride.slug - The slug value of the account page override (Standard Page)
 * @param {string} account.accountPageOverride.template - The template value of the account page override (Standard Page)
 * @param {string} account.accountPageOverride.urlSlug - The URL slug value of the account page override (Product Detail Page)
 * @param {string} locale - The locale to get the link for
 * @returns {string|null} The account link.
 */
export const _getAccountLink = ({ urlSlug, accountPageOverride }, locale = DEFAULT_LOCALE) => {
  if (!accountPageOverride) {
    return ACCOUNTS_WITHOUT_PAGES.includes(urlSlug) ? null : `/accounts/${urlSlug}`;
  }
  // Open pages do not have French variants
  if (accountPageOverride?.template === 'open' && isEquivalentLocale(locale, 'fr')) {
    return null;
  }
  // Currently the override link is always a standard page, but it
  // could, in theory use a Product Detail Page, which uses the urlSlug field name
  const slugValue = accountPageOverride.slug ?? accountPageOverride.urlSlug;
  return `/${slugValue.split('/').filter(path => path).join('/')}`;
};

/**
 * A hook to get a version of getAccountLink that is locale-aware. Call at the top level of your component.
 *
 * @returns {function} A getAccountLink function with the locale pre-populated
 */
export const useGetAccountLink = () => {
  const { locale } = useTranslationContext();
  return account => _getAccountLink(account, locale);
};

/**
 * Sorts the accounts based on the preferred order specified by the orderedAccountUrlSlugs array.
 *
 * @param {Array} accounts - The array of account objects returned from Contentful.
 * @param {Array} orderedAccountUrlSlugs - The array of account `urlSlug` values in the preferred order.
 * @returns {void}
 */
export const sortAccountsByPreferredOrder = (accounts = [], orderedAccountUrlSlugs = []) => {
  return accounts.sort((a, b) => {
    const [aIndex, bIndex] = [a, b].map(account => {
      const priority = orderedAccountUrlSlugs.indexOf(account?.urlSlug);
      if (priority === -1) {
        return 100;
      }
      return priority;
    });
    return aIndex - bIndex;
  });
};

export const formatAccountPageData = (pageData = {}) => {
  const { currentAccountData, compareAccountData, accountName, accountShortName, ...restData } = formatTableData(pageData);
  const heroHeading = `${accountName}${accountShortName ? ` (${accountShortName})` : ''}`;
  return { currentAccountData, heroHeading, compareAccountData, ...restData };
};

const formatTableData = (pageData = {}) => {
  const {
    compareAccount,
    compareAnnualContributionLimit,
    compareContributionDeadline,
    compareEligibility,
    compareGovernmentBenefits,
    compareHelpsYouSaveFor,
    compareTaxImpactContributions,
    compareTaxImpactWithdrawals,
    compareWithdrawalStipulations,
    ...restData
  } = pageData;

  // destructure separately as we still want these to be returned in object
  const { urlSlug, accountPageOverride, accountName, accountShortName } = pageData;

  const currentAccountData = {
    urlSlug,
    accountPageOverride,
    compareAccountName: accountShortName ?? accountName,
    compareAnnualContributionLimit,
    compareContributionDeadline,
    compareEligibility,
    compareGovernmentBenefits,
    compareHelpsYouSaveFor,
    compareTaxImpactContributions,
    compareTaxImpactWithdrawals,
    compareWithdrawalStipulations,
  };

  if (compareAccount) {
    return { currentAccountData, compareAccountData: formatAccountPageData(compareAccount).currentAccountData, ...restData };
  }

  return { currentAccountData, ...restData };
};
