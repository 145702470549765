import { REFRESH_BREAKPOINTS } from 'src/utils/css';
import { useMatchMedia } from '../useMatchMedia/useMatchMedia';

const mqMinWidthPx = width => `(min-width: ${width}px)`;
const mqMaxWidthPx = width => `(max-width: ${width}px)`;
const mqMinMaxWidthPx = (min, max) => `(min-width: ${min}px) and (max-width: ${max - 1}px)`;

export const useBreakpoints = () => {
  const breakpoints = {
    isXs: useMatchMedia(mqMaxWidthPx(REFRESH_BREAKPOINTS.sm - 1)),
    isSm: useMatchMedia(mqMinMaxWidthPx(REFRESH_BREAKPOINTS.sm, REFRESH_BREAKPOINTS.md - 1)),
    isMd: useMatchMedia(mqMinMaxWidthPx(REFRESH_BREAKPOINTS.md, REFRESH_BREAKPOINTS.lg - 1)),
    isLg: useMatchMedia(mqMinMaxWidthPx(REFRESH_BREAKPOINTS.lg, REFRESH_BREAKPOINTS.xl - 1)),
    isXl: useMatchMedia(mqMinWidthPx(REFRESH_BREAKPOINTS.xl)),
  };

  breakpoints.active =
    Object.keys(breakpoints)
      .find(key => breakpoints[key])
      ?.slice(-2)
      ?.toLowerCase() ?? 'xs';

  return breakpoints;
};
