import React from 'react';
import { KeyPoints } from 'src/components/subsections/KeyPoints';
import { KeyStats } from 'src/components/subsections/KeyStats';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { spacesToCamel } from 'src/utils/strings';
import { COLUMN_EYEBROW_VALUES } from './ContentfulKeyPointsHorizontal.config';

export const ContentfulKeyPointsHorizontal = ({
  sectionHeading = {},
  list,
  layout,
  columnEyebrows: providedColumnEyebrows,
  ...subsectionProps
}) => {
  const columnEyebrows = spacesToCamel(providedColumnEyebrows);
  const isStatsLayout = layout === 'stats';

  const ListComponent = isStatsLayout ? KeyStats : KeyPoints;
  const { heading, subheading } = sectionHeading;
  const { getStringWithGlobals } = useTranslationContext();

  const listWithGlobals = list?.map(({ heading: listHeading, details, ...rest }, index) => ({
    heading: getStringWithGlobals(listHeading),
    details: getStringWithGlobals(details),
    preHeading: isStatsLayout && COLUMN_EYEBROW_VALUES?.[columnEyebrows]?.[index],
    ...rest,
  }));

  return (
    <>
      {sectionHeading && (
        <SectionHeading
          {...subsectionProps}
          heading={getStringWithGlobals(heading)}
          subheading={getStringWithGlobals(subheading)}
          textAlign="left"
          addBottomSpacing
        />
      )}
      {list && list.length > 0 && <ListComponent {...subsectionProps} list={listWithGlobals} />}
    </>
  );
};
