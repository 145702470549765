import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { REFRESH_BREAKPOINTS, classNames } from 'src/utils/css';
import { RefreshCell, RefreshGrid, useComponentContext } from 'src/components/layout';
import { FluidText } from 'src/components/common/FluidText';
import { Accordion } from 'src/tapestry/core/accordion';
import { Tooltip } from 'src/tapestry/core/tooltip';
import { Info } from 'src/components/common/Icons';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { formatCurrency, formatDecimalToPercentage } from 'src/utils/formatters';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { capitalizeFirstLetter } from 'src/utils/strings';
import { setDecimalPrecision } from 'src/utils/math';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { PAYMENT_FREQUENCY_OPTIONS, MONTHS_IN_YEAR, pmtRate } from '../../MortgageRebateV2.helpers';
import { wrapper_, breakdownHeading_, firstHeading_, caret_, caretOpen_, caretClosed_, accordionButtonWrapper_, accordion_, detailList_, detailBorderWrapper_, totalSavingsBar_, bottomSpacer_ } from './BreakdownTable.module.scss';

const BreakdownHeading = ({ value, description, tooltipDescription, testId, className }) => {
  return (
    <div className={classNames(breakdownHeading_, className)}>
      <FluidText type="h3" min="ws-display-md" max="ws-display-xl" data-testid={testId}>{value}</FluidText>
      <FluidText type="p" style={{ display: 'inline-block' }} min="ws-text-md-medium" max="ws-text-lg-medium">{description}</FluidText>
      {`\u00a0`}
      <Tooltip ariaLabel={description} description={tooltipDescription} forceVerticalAlignment="top" removeUnderline alignTipToContentCenter style={{ transform: 'translateY(-2px)', zIndex: 2 }}><Info /></Tooltip>
    </div>
  );
};

const AccordionButton = ({ ariaControlsId, isOpen = false, label, ...rest }) => {
  return (
    <div className={accordionButtonWrapper_}>
      <button
        type="button"
        aria-controls={ariaControlsId}
        aria-expanded={isOpen}
        {...rest}
      >
        {label && (
          <>
            <FluidText type="span" all="ws-text-sm-medium">{label}</FluidText>
            {`\u00a0`}
          </>
        )}
        <span
          className={classNames(caret_, isOpen ? caretOpen_ : caretClosed_)}
          aria-hidden="true"
        >
          <span />
          <span />
        </span>
      </button>
    </div>
  );
};

const DetailList = ({ items = [] }) => {
  const FLUID_TEXT_CONFIG = {
    bold: { min: 'ws-text-md-medium', max: 'ws-text-lg-medium' },
    default: { min: 'ws-text-md', max: 'ws-text-lg' },
  };
  return (
    <dl className={detailList_}>
      {/* TIL <div> can be child of dl element https://html.spec.whatwg.org/multipage/grouping-content.html#the-dl-element */}
      {items.map(({ label, value, bold: isBold }) => (
        <FluidText key={`breakdown-table-row-${label}`} type="div" {...FLUID_TEXT_CONFIG[isBold ? 'bold' : 'default']} className={detailBorderWrapper_}>
          <dt>{label}</dt>
          <dd>{value}</dd>
        </FluidText>
      ))}
    </dl>
  );
};

const SummaryBar = ({ savings, term, testId }) => {
  return (
    <FluidText type="div" min="ws-text-md" max="ws-text-lg" className={totalSavingsBar_} data-testid={testId}>
      {`That's a total savings of `}
      <FluidText type="span" min="ws-text-md-medium" max="ws-text-lg-medium">{savings}</FluidText>
      {` over a `}
      {term}
      -year term
    </FluidText>
  );
};

export const MobileTable = ({ columnOne, columnTwo, totalSavings, isOpenOnLoad }) => {
  const componentContext = useComponentContext();
  const ACCORDION_ID_UPPER = 'mobile-accordion-upper';
  const ACCORDION_ID_LOWER = 'mobile-accordion-lower';
  const [isOpenUpper, setIsOpenUpper] = useState(isOpenOnLoad);
  const [isOpenLower, setIsOpenLower] = useState(isOpenOnLoad);
  const handleMobileExpandUpper = () => {
    const currentIsOpenUpper = !isOpenUpper;
    setIsOpenUpper(currentIsOpenUpper);
    trackAnalyticsEvent(EVENT_NAMES.FULL_BREAKDOWN_TOGGLE_MOBILE_UPPER, { label: currentIsOpenUpper ? 'open' : 'close' }, componentContext);
  };
  const handleMobileExpandLower = () => {
    const currentIsOpenLower = !isOpenLower;
    setIsOpenLower(currentIsOpenLower);
    trackAnalyticsEvent(EVENT_NAMES.FULL_BREAKDOWN_TOGGLE_MOBILE_LOWER, { label: currentIsOpenLower ? 'open' : 'close' }, componentContext);
  };
  return (
    <>
      <RefreshCell>
        <BreakdownHeading value={columnOne.value} description={columnOne.description} tooltipDescription={columnOne.tooltip} testId="heading-rate" />
        <AccordionButton ariaControlsId={ACCORDION_ID_UPPER} onClick={handleMobileExpandUpper} isOpen={isOpenUpper} data-testid="mobile-upper-breakdown-button" />
        <Accordion id={ACCORDION_ID_UPPER} isOpen={isOpenUpper} innerClassName={accordion_}>
          <DetailList items={columnOne.items} />
        </Accordion>
      </RefreshCell>
      <RefreshCell>
        <BreakdownHeading value={columnTwo.value} description={columnTwo.description} tooltipDescription={columnTwo.tooltip} testId="heading-payment" />
        <AccordionButton ariaControlsId={ACCORDION_ID_LOWER} onClick={handleMobileExpandLower} isOpen={isOpenLower} data-testid="mobile-lower-breakdown-button" />
        <Accordion id={ACCORDION_ID_LOWER} isOpen={isOpenLower} innerClassName={accordion_}>
          <DetailList items={columnTwo.items} />
          <SummaryBar {...totalSavings} testId="summary-bar" />
        </Accordion>
        <div className={bottomSpacer_} />
      </RefreshCell>
    </>
  );
};

export const DesktopTable = ({ columnOne, columnTwo, totalSavings, isOpenOnLoad }) => {
  const componentContext = useComponentContext();
  const [isOpen, setIsOpen] = useState(isOpenOnLoad);
  const handleDesktopExpand = () => {
    const currentIsOpen = !isOpen;
    setIsOpen(currentIsOpen);
    trackAnalyticsEvent(EVENT_NAMES.FULL_BREAKDOWN_TOGGLE_DESKTOP, { label: currentIsOpen ? 'open' : 'close' }, componentContext);
  };

  return (
    <>
      <RefreshCell lg={6}>
        <BreakdownHeading value={columnOne.value} description={columnOne.description} tooltipDescription={columnOne.tooltip} className={firstHeading_} />
      </RefreshCell>
      <RefreshCell lg={6}>
        <BreakdownHeading value={columnTwo.value} description={columnTwo.description} tooltipDescription={columnTwo.tooltip} />
      </RefreshCell>
      <RefreshCell>
        <AccordionButton label="Full breakdown" onClick={handleDesktopExpand} isOpen={isOpen} data-testid="desktop-breakdown-button" />
        <Accordion isOpen={isOpen} innerClassName={accordion_}>
          <DetailList items={columnOne.items} />
          <DetailList items={columnTwo.items} />
          <SummaryBar {...totalSavings} />
        </Accordion>
        <div className={bottomSpacer_} />
      </RefreshCell>
    </>
  );
};

export const BreakdownTable = forwardRef(function BreakdownTable({
  className = '',
  amortizationPeriod = 300,
  clientStatusRebate = 0.001,
  depositRebate = 0.004,
  depositValue = 400000,
  interestRate = 4.64,
  mortgageAmount = 500000,
  paymentFrequency = 12,
  termDuration = 60,
  tierLevel = 'premium',
  isOpenOnLoad = false,
  ...rest
}, ref) {
  const isDesktop = useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.lg}px)`);
  const { locale } = useTranslationContext();

  const decimalToPercent = decimal => formatDecimalToPercentage(decimal, { locale, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const interestRateNormalized = interestRate / 100;
  let wealthsimpleRebate = Math.min(interestRateNormalized, clientStatusRebate + depositRebate);
  wealthsimpleRebate = setDecimalPrecision(wealthsimpleRebate, 4);
  const interestRateAfterRebates = interestRateNormalized - wealthsimpleRebate;
  const equivalentMortgageRate = `${decimalToPercent(interestRateAfterRebates)}`;
  const prefixValue = val => (val > 0 ? '-' : '');

  const columnOne = {
    value: equivalentMortgageRate,
    description: `Hypothetical mortgage rate`,
    tooltip: `This is a hypothetical rate intended to demonstrate the interest rate that would generate a mortgage payment equal to your mortgage payment less any applicable rebates. The actual interest rate charged is per Pine's contract and does not change with rebates.`,
    items: [
      { label: 'Pine mortgage rate', value: `${decimalToPercent(interestRateNormalized)}`, bold: true },
      { label: 'Wealthsimple rebate', value: `${prefixValue(wealthsimpleRebate)} ${decimalToPercent(wealthsimpleRebate)}`, bold: true },
      { label: `${capitalizeFirstLetter(tierLevel)} rebate`, value: `${prefixValue(clientStatusRebate)} ${decimalToPercent(clientStatusRebate)}` },
      { label: `${formatCurrency(depositValue, { locale })} deposit rebate`, value: `${prefixValue(depositRebate)} ${decimalToPercent(depositRebate)}` },
    ]
  };

  const monthlyPmRateNoRebate = pmtRate(interestRateNormalized, MONTHS_IN_YEAR, amortizationPeriod / MONTHS_IN_YEAR, mortgageAmount);
  const pmRateNoRebateSelectedFrequency = Math.abs((monthlyPmRateNoRebate * MONTHS_IN_YEAR) / paymentFrequency);
  const monthlyPmRateWithRebate = pmtRate(interestRateAfterRebates, MONTHS_IN_YEAR, amortizationPeriod / MONTHS_IN_YEAR, mortgageAmount);
  const pmRateWithRebateSelectedFrequency = (monthlyPmRateWithRebate * MONTHS_IN_YEAR) / paymentFrequency;
  const equivalentMortgagePayment = formatCurrency(Math.abs(pmRateWithRebateSelectedFrequency), { locale, maximumFractionDigits: 0 });

  const currentPaymentFrequency = PAYMENT_FREQUENCY_OPTIONS.find(({ value }) => value === paymentFrequency);
  const paymentFrequencyLabel = currentPaymentFrequency.label.toLocaleLowerCase();

  const totalRebateMontly = monthlyPmRateNoRebate - monthlyPmRateWithRebate;
  const columnTwo = {
    value: equivalentMortgagePayment,
    description: `Equivalent ${paymentFrequencyLabel} payment`,
    tooltip: `This is an estimated amount which you would pay if you were to apply your rebate(s) as prepayments to your mortgage. However, the actual monthly payment obligations are per Pine’s contract and do not change. Wealthsimple rebates are paid out on a monthly basis.`,
    items: [
      { label: `Your ${paymentFrequencyLabel} payment`, value: `${formatCurrency(pmRateNoRebateSelectedFrequency, { locale, maximumFractionDigits: 0 })}`, bold: true },
      { label: `Monthly rebate`, value: `${prefixValue(totalRebateMontly)} ${formatCurrency(Math.round(totalRebateMontly))}`, bold: true },
    ]
  };

  const termDurationInYears = termDuration / MONTHS_IN_YEAR;
  const savings = Math.round(totalRebateMontly * MONTHS_IN_YEAR * termDurationInYears);
  const totalSavings = { savings: formatCurrency(savings, { locale, maximumFractionDigits: 0 }), term: termDurationInYears };

  return (
    <div ref={ref} className={classNames(wrapper_, className)} {...rest}>
      <RefreshGrid all={{ gap: 0 }}>
        {isDesktop
          ? (<DesktopTable
              columnOne={columnOne}
              columnTwo={columnTwo}
              totalSavings={totalSavings}
              isOpenOnLoad={isOpenOnLoad}
          />)
          : (<MobileTable
              columnOne={columnOne}
              columnTwo={columnTwo}
              totalSavings={totalSavings}
              isOpenOnLoad={isOpenOnLoad}
          />)}
      </RefreshGrid>
    </div>
  );
});

BreakdownTable.propTypes = {
  className: PropTypes.string,
  isOpenOnLoad: PropTypes.bool,
};
