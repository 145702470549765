import React from 'react';
import { LogoShowcase } from 'src/components/subsections/LogoShowcase';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { REFRESH_BREAKPOINTS } from 'src/utils/css';
import { withFramework } from 'lib/get-framework';
import { translations } from './ContentfulWorkLogoShowcase.translations';

import oslerLogo from './media/osler-logo.webp';
import loopioLogo from './media/loopio-logo.webp';
import achieversLogo from './media/achievers-logo.webp';
import leagueLogo from './media/league-logo.webp';
import knixLogo from './media/knix-logo.webp';

const ContentfulWorkLogoShowcase = subsectionProps => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const isLargerScreen = useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.sm}px)`);

  const contentfulLogoShowcaseAssets = [
    {
      id: 'osler-logo',
      asset: (
        <img
          src={withFramework({ gatsby: () => oslerLogo, next: () => oslerLogo.src })}
          alt={useTranslation('contentful-logo-showcase::logo-alt-text::osler-logo')}
          height={44}
          width={160}
        />
      ),
    },
    {
      id: 'loopio-logo',
      asset: (
        <img
          src={withFramework({ gatsby: () => loopioLogo, next: () => loopioLogo.src })}
          alt={useTranslation('contentful-logo-showcase::logo-alt-text::loopio-logo')}
          height={47}
          width={132}
        />
      ),
    },
    {
      id: 'achievers-logo',
      asset: (
        <img
          src={withFramework({ gatsby: () => achieversLogo, next: () => achieversLogo.src })}
          alt={useTranslation('contentful-logo-showcase::logo-alt-text::achievers-logo')}
          height={46}
          width={160}
        />
      ),
    },
    {
      id: 'league-logo',
      asset: (
        <img
          src={withFramework({ gatsby: () => leagueLogo, next: () => leagueLogo.src })}
          alt={useTranslation('contentful-logo-showcase::logo-alt-text::league-logo')}
          height={48}
          width={160}
        />
      ),
    },
    {
      id: 'knix-logo',
      asset: (
        <img
          src={withFramework({ gatsby: () => knixLogo, next: () => knixLogo.src })}
          alt={useTranslation('contentful-logo-showcase::logo-alt-text::knix-logo')}
          height={isLargerScreen ? 48 : 16}
          width={isLargerScreen ? 103 : 34}
        />
      ),
    },
  ];

  return (<LogoShowcase logos={contentfulLogoShowcaseAssets} {...subsectionProps} />);
};

export { ContentfulWorkLogoShowcase };
