import {
  colorSlate95,
  colorLinenBg,
  colorNavyBg,
} from 'src/styles/exports.module.scss';
import { TIER_NAMES } from 'src/utils/tiers';

export const TIERS_EYEBROWS = {
  [TIER_NAMES.CORE]: { background: colorSlate95, translationKey: 'eyebrow::tiers-thresholds::core' },
  [TIER_NAMES.PREMIUM]: { background: colorLinenBg, translationKey: 'eyebrow::tiers-thresholds::premium' },
  [TIER_NAMES.GENERATION]: { background: colorNavyBg, translationKey: 'eyebrow::tiers-thresholds::generation' },
};
