import React from 'react';
import { asSubsection } from 'src/components/layout';
import { MortgageRebateV2 } from '../MortgageRebateV2';
import { MortgageCalculatorProvider } from '../MortgageRebateV2/components/CalculatorProvider/CalculatorProvider.component';

export const MortgageCalculator = asSubsection('MortgageCalculator')(
  ({ heading, subheading, ...subsectionProps }) => {
    return (
      <>
        <div style={{ position: 'absolute', background: 'var(--calculator-tier-color)', width: '100%', height: '100%', top: 0, left: 0, zIndex: -1 }} />
        <MortgageCalculatorProvider>
          <MortgageRebateV2 heading={heading} subheading={subheading} {...subsectionProps} />
        </MortgageCalculatorProvider>
      </>
    );
  }
);
