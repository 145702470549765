import React, { createContext, useReducer, useContext, useMemo } from 'react';
import { calculatorReducer, initialState } from './CalculatorProvider.reducer';

const MortgageCalculatorContext = createContext();

export const MortgageCalculatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(calculatorReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <MortgageCalculatorContext.Provider value={contextValue}>
      {children}
    </MortgageCalculatorContext.Provider>
  );
};

export const useMortgageCalculatorData = () => {
  const context = useContext(MortgageCalculatorContext);
  if (context === undefined) {
    throw new Error('useMortgageCalculator must be used within a MortgageCalculatorProvider');
  }
  return context;
};
