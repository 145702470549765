export const translations = {
  'en-ca': {
    'contentful-tier-cards::tier-card::Core::eyebrow': '{{AUM_THRESHOLD_CORE}} in assets',
    'contentful-tier-cards::tier-card::Core::heading': 'Core',
    'contentful-tier-cards::tier-card::Core::body': 'Get started with simple, low-fee financial tools.',
    'contentful-tier-cards::tier-card::Premium::eyebrow': '{{AUM_THRESHOLD_PREMIUM}} in assets',
    'contentful-tier-cards::tier-card::Premium::heading': 'Premium',
    'contentful-tier-cards::tier-card::Premium::body': 'Make the most of your investments with lower fees and tailored advice.',
    'contentful-tier-cards::tier-card::Generation::eyebrow': '{{AUM_THRESHOLD_GENERATION}} in assets',
    'contentful-tier-cards::tier-card::Generation::heading': 'Generation',
    'contentful-tier-cards::tier-card::Generation::body': 'Build your legacy with expert guidance from your dedicated team of advisors.',
  },
  'fr-ca': {
    'contentful-tier-cards::tier-card::Core::eyebrow': `{{AUM_THRESHOLD_CORE}} d'actifs`,
    'contentful-tier-cards::tier-card::Core::heading': 'Essentiel',
    'contentful-tier-cards::tier-card::Core::body': 'Commencez avec des outils financiers simples et à peu de frais.',
    'contentful-tier-cards::tier-card::Premium::eyebrow': `{{AUM_THRESHOLD_PREMIUM}} d'actifs`,
    'contentful-tier-cards::tier-card::Premium::heading': 'Avantage',
    'contentful-tier-cards::tier-card::Premium::body': 'Tirez le maximum de vos placements grâce à des frais réduits et à des conseils sur mesure.',
    'contentful-tier-cards::tier-card::Generation::eyebrow': `{{AUM_THRESHOLD_GENERATION}} d'actifs`,
    'contentful-tier-cards::tier-card::Generation::heading': 'Génération',
    'contentful-tier-cards::tier-card::Generation::body': 'Bâtissez votre patrimoine grâce aux conseils avisés de votre équipe-conseil attitrée.',
  },
};
