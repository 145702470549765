'use client';

import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import { GRID_DEFAULT_CONFIG, RefreshCell, RefreshGrid } from 'src/components/layout';
import { TabSelector } from 'src/components/common/TabSelector';
import { CustomEase } from 'src/utils/animation/gsap-plugins/CustomEase';
import { cardGrid_, card_ } from './CardsWithFilter.module.scss';
import { Card } from '../Card';

const ANIMATION_STEP_ONE_DURATION = 0.042;
gsap.registerPlugin(CustomEase);

const CardGrid = ({ cards, tagFilter, ...rest }) => {
  const lastFilter = useRef(tagFilter);
  const [innerFilter, setInnerFilter] = useState(tagFilter);

  useLayoutEffect(() => {
    const last = lastFilter.current;
    lastFilter.current = tagFilter;
    if (last === tagFilter) return;

    gsap.to('[data-card]', {
      duration: ANIMATION_STEP_ONE_DURATION,
      opacity: 0,
      ease: 'card-out',
      delay: ANIMATION_STEP_ONE_DURATION,
      onComplete: () => {
        setInnerFilter(tagFilter);
        gsap.delayedCall(ANIMATION_STEP_ONE_DURATION, () => {
          gsap.fromTo(
            '[data-card]',
            { y: 40, opacity: 0 },
            {
              duration: 0.417,
              y: 0,
              opacity: 1,
              ease: 'card-in',
            }
          );
        });
      },
    });
  }, [tagFilter]);

  useLayoutEffect(() => {
    CustomEase.create('card-in', '0.05, 0.0, 0.0, 1.0');
    CustomEase.create('card-out', '0.33, 0.00, 0.66, 1.00');
    gsap.set('[data-card]', { opacity: 1 });
  }, []);

  return (
    <RefreshGrid {...rest} xs={{ columnGap: 0 }} lg={{ columnGap: GRID_DEFAULT_CONFIG.gap }}>
      {cards.map(card => {
        return (
          card.tags.includes(innerFilter) && (
            <RefreshCell key={card.heading} lg={{ width: 4 }}>
              <Card
                className={card_}
                link={card.link}
                heading={card.heading}
                headingSupport={card.headingSupport}
                pill={card.pill}
                subheading={card.subheading}
                list={card.list}
                data-card
              />
            </RefreshCell>
          )
        );
      })}
    </RefreshGrid>
  );
};

export const CardsWithFilter = ({ cardData, tabData, ...rest }) => {
  const [activeTab, setActiveTab] = useState(tabData[0]);

  const handleChange = tabName => {
    if (tabName !== activeTab) {
      setActiveTab(tabName);
    }
  };

  return (
    <div {...rest}>
      <TabSelector tabs={tabData} onChange={handleChange} />
      <CardGrid cards={cardData} className={cardGrid_} tagFilter={activeTab} />
    </div>
  );
};

CardsWithFilter.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.string).isRequired,
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
      tags: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
    })
  ).isRequired,
};
