export const translations = {
  'en-ca': {
    'invest-graph::label::ws': `Investing with Wealthsimple`,
    'invest-graph::label::mf': `Investing with mutual funds`,
    'invest-graph::timeline::label::start': `$100,000 invested today + $10,000 annually`,
    'invest-graph::timeline::label::end': `25 years from now`,
    'invest-graph::disclosure': `All returns data are hypothetical and for illustrative purposes only. Returns are not indicative of expected or estimated return rates. [See disclaimer](/legal/managed-investing-fee-chart-disclaimer).`,
    'invest-graph::title': `Managed Investing Fee Chart`,
    'invest-graph::description': `A graph showing Wealthsimple returns ($1,221,896) versus mutual fund returns ($947,994).`,
  },
  'fr-ca': {
    'invest-graph::label::ws': `Avec Wealthsimple`,
    'invest-graph::label::mf': `Avec les fonds communs`,
    'invest-graph::timeline::label::start': `Placement initial de 100\u00A0000\u00A0$ + 10\u00A0000\u00A0$ par année`,
    'invest-graph::timeline::label::end': `Dans 25 ans`,
    'invest-graph::disclosure': `Toutes les données sur le rendement sont hypothétiques et fournies uniquement à des fins d'illustration. Les rendements ne sont pas représentatifs des taux de rendement attendu ou estimé. [Voir la divulgation complète](/legal/managed-investing-fee-chart-disclaimer).`,
  },
};
