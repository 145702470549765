import React, { useRef, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { classNames, REFRESH_BREAKPOINTS } from 'src/utils/css';
import { A11yAnimatedScrollBlock } from 'src/tapestry/animation/animated-scroll-block';
import { Media } from 'src/tapestry/core/media';
import { VideoControls } from 'src/components/common/VideoControls';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { borderRadiusDefault } from 'src/styles/exports.module.scss';
import {
  mediaAndControlsWrapper,
  productMedia,
  productMediaWrapper,
  scrollWrapper,
  scrollTrigger,
  controlsWrapper,
  videoControls,
} from './ProductHeroMedia.module.scss';

const ANIMATION_OPT_IN_QUERY = '(prefers-reduced-motion: no-preference)';
const CONTAIN_MODE = 'contain';

const timelineFunction = (config, ref, scaleAmtStart, scaleAmtEnd) => {
  const timeline = gsap.timeline(config);
  const assetElement = ref.current?.querySelector('.mediaAssetWrapper');

  timeline.fromTo(
    ref.current,
    {
      clipPath: `inset(0px 32px round ${borderRadiusDefault})`,
    },
    {
      clipPath: `inset(0px 0px round 0px)`,
      ease: 'power2.out',
      duration: 0.6,
      overwrite: 'auto',
    },
    0
  );

  timeline.fromTo(
    assetElement,
    {
      width: `${scaleAmtStart * 100}%`,
      marginLeft: `${((scaleAmtStart - 1) / 2) * 100 * -1}%`,
    },
    {
      width: `${scaleAmtEnd * 100}%`,
      marginLeft: `${((scaleAmtEnd - 1) / 2) * 100 * -1}%`,
      ease: 'power2.out',
      duration: 1,
      overwrite: 'auto',
    },
    0
  );

  return () => {
    if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
    if (timeline) timeline.kill();
  };
};

const productHeroTimeline = (config, dependencies) => {
  const { ref, isObjectFitContain } = dependencies;
  return {
    [`(max-width: ${REFRESH_BREAKPOINTS.md - 1}px) and ${ANIMATION_OPT_IN_QUERY}`]: () =>
      timelineFunction(config, ref, 1.25, 1.47, isObjectFitContain),
    // Need to force an update
    [`(min-width: ${REFRESH_BREAKPOINTS.md}px) and (max-width: ${REFRESH_BREAKPOINTS.lg -
      1}px) and ${ANIMATION_OPT_IN_QUERY}`]: () =>
      timelineFunction(config, ref, 1, 1.2, isObjectFitContain),
    [`(min-width: ${REFRESH_BREAKPOINTS.lg}px) and ${ANIMATION_OPT_IN_QUERY}`]: () => {
      // Reset on resize - can't seem to make this happen without using fromTo
      const timeline = gsap.timeline(config);
      const assetElement = ref.current?.querySelectorAll('.mediaAssetWrapper')[0];

      timeline.fromTo(
        ref.current,
        { clipPath: `inset(0px 0px round ${borderRadiusDefault})` },
        { clipPath: `inset(0px 0px round ${borderRadiusDefault})`, overwrite: 'auto' },
        0
      );
      timeline.fromTo(
        assetElement,
        { width: '100%', marginLeft: 0 },
        { width: '100%', marginLeft: 0 },
        0
      );
      return () => {
        if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
        if (timeline) timeline.kill();
      };
    },
  };
};

const ProductHeroMedia = ({ objectFit, className, releaseAspectRatio = false, ...rest }) => {
  const ref = useRef();
  const [videoEl, setVideoEl] = useState();
  const { isPowerSaveMode } = useUserAttributesContext();

  const _releaseAspectRatio = releaseAspectRatio || objectFit !== CONTAIN_MODE;

  const mediaContent = (
    <div ref={ref} className={classNames(productMediaWrapper, className)}>
      <Media
        className={classNames(productMedia)}
        releaseAspectRatio={_releaseAspectRatio}
        objectFit={objectFit}
        {...rest}
        lazyload={false}
      />
    </div>
  );

  useEffect(() => {
    const videoElement = ref.current?.getElementsByTagName('video')[0];
    if (videoElement) setVideoEl(videoElement);
  }, [videoEl]);

  return (
    <>
      <div className={scrollTrigger} />
      <div className={mediaAndControlsWrapper}>
        <A11yAnimatedScrollBlock
          className={scrollWrapper}
          trigger={`.${scrollTrigger}`}
          timeline={productHeroTimeline}
          startPosition="top top"
          endPosition="bottom+=60% bottom"
          scrub={0.15}
          timelineDependencies={{ ref }}
        >
          {mediaContent}
        </A11yAnimatedScrollBlock>
        {videoEl && !isPowerSaveMode && (
          <div className={controlsWrapper}>
            <VideoControls className={videoControls} videoElement={videoEl} />
          </div>
        )}
      </div>
    </>
  );
};

export { ProductHeroMedia };
