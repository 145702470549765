import React from 'react';
import { TierEyebrow } from 'src/components/common/Eyebrow';
import { TIER_NAMES } from 'src/utils/tiers';

export const COLUMN_EYEBROW_OPTIONS = {
  TIERS_THRESHOLDS: 'tiersThresholds',
};

export const COLUMN_EYEBROW_VALUES = {
  [COLUMN_EYEBROW_OPTIONS.TIERS_THRESHOLDS]: [
    <TierEyebrow key="core" tierName={TIER_NAMES.CORE} />,
    <TierEyebrow key="premium" tierName={TIER_NAMES.PREMIUM} />,
    <TierEyebrow key="generation" tierName={TIER_NAMES.GENERATION} />,
  ]
};
