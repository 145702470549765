import React from 'react';
import PropTypes from 'prop-types';
import { withFramework } from 'lib/get-framework';
import { FilterableAccountCards } from 'src/components/subsections/FilterableAccountCards';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { Stack } from 'src/components/layout/Stack/Stack.layout';
import { filterableCardsStack_ } from './ContentfulFilterableAccountCards.module.scss';

export const ContentfulFilterableAccountCards = ({ heading, subheading, accountDetails, ...subsectionProps }) => {
  return (
    <Stack className={filterableCardsStack_}>
      <SectionHeading heading={heading} subheading={subheading} textAlign="left" {...subsectionProps} addBottomSpacing={false} {...subsectionProps} />
      <FilterableAccountCards
        accountDetails={withFramework({
          gatsby: () => accountDetails?.nodes,
          next: () => accountDetails,
        })}
        {...subsectionProps}
      />
    </Stack>);
};

ContentfulFilterableAccountCards.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  accountDetails: PropTypes.shape({
    node: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
