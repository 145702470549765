import React from 'react';
import PropTypes from 'prop-types';
import { RefreshGrid } from 'src/components/layout';
import { classNames } from 'src/utils/css';
import { bodyRow_ } from './TableRow.module.scss';

export const TableRow = ({ isHeader: isRowInTableHead = false, children, className }) => (
  <RefreshGrid
    as="tr"
    all={{ gap: 0 }}
    className={classNames(!isRowInTableHead && bodyRow_, className)}
  >
    {React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          numCols: React.Children.count(children),
          // Because Markdown gives us no way to differentiate row headers from
          // row cells, for our purposes in the current design we must assume
          // that the first cell in a tbody row is a row header, and that _all_
          // cells in a thead row are column headers.
          isColHeader: isRowInTableHead,
          isRowHeader: !isRowInTableHead && index === 0,
          isFirstCellInRow: index === 0,
        });
      }
      return child;
    })}
  </RefreshGrid>
);

TableRow.propTypes = {
  isHeader: PropTypes.bool, // set by react-markdown, or can be set directly
};
