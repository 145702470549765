import React from 'react';
import { MortgageCalculator } from 'src/components/common/MortgageCalculator/MortgageCalculator.subsection.js';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { ContentfulFeeSavingsChart } from 'src/components/contentful/ContentfulFeeSavingsChart';
import { ContentfulWorkLogoShowcase } from 'src/components/contentful/ContentfulWorkLogoShowcase';
import { ContentfulFilterableAccountCards } from '../ContentfulFilterableAccountCards';

export const SPECIAL_CONTENT_SELECTIONS = {
  MORTGAGE_CALCULATOR: 'Mortgage Calculator',
  MANAGED_INVESTING_FEE_SAVINGS_CHART: 'Managed Investing Fee Savings Chart',
  WORK_LOGO_SHOWCASE: 'Work Logo Showcase',
  FILTERABLE_ACCOUNT_CARDS: 'Filterable Account Cards',
};

const specialContentSelectionToCustomSubsectionConfig = {
  [SPECIAL_CONTENT_SELECTIONS.MORTGAGE_CALCULATOR]: {
    hasSeparateHeading: false,
    CustomSubsection: MortgageCalculator
  },
  [SPECIAL_CONTENT_SELECTIONS.MANAGED_INVESTING_FEE_SAVINGS_CHART]: {
    hasSeparateHeading: false,
    CustomSubsection: ContentfulFeeSavingsChart,
  },
  [SPECIAL_CONTENT_SELECTIONS.WORK_LOGO_SHOWCASE]: {
    hasSeparateHeading: false,
    CustomSubsection: ContentfulWorkLogoShowcase,
  },
  [SPECIAL_CONTENT_SELECTIONS.FILTERABLE_ACCOUNT_CARDS]: {
    hasSeparateHeading: false,
    CustomSubsection: ContentfulFilterableAccountCards
  },
};

export const ContentfulSpecialContent = ({
  sectionHeading,
  specialContentSelection,
  ...subsectionProps
}) => {
  const { getStringWithGlobals } = useTranslationContext();

  const { heading, subheading } = sectionHeading;
  const { hasSeparateHeading, CustomSubsection } = specialContentSelectionToCustomSubsectionConfig[specialContentSelection] ||
  { hasSeparateHeading: true, CustomSubsection: null };

  const headingWithGlobals = getStringWithGlobals(heading);
  const subheadingWithGlobals = getStringWithGlobals(subheading);

  return (
    <>
      {hasSeparateHeading && sectionHeading && (
        <SectionHeading
          heading={headingWithGlobals}
          subheading={subheadingWithGlobals}
          textAlign="left"
          {...subsectionProps}
          addBottomSpacing
        />
      )}
      {CustomSubsection && (
        <CustomSubsection
          heading={hasSeparateHeading ? undefined : headingWithGlobals}
          subheading={hasSeparateHeading ? undefined : subheadingWithGlobals}
          {...subsectionProps}
        />
      )}
    </>
  );
};
