import { colorSlate95, colorLinenBg, colorMidnightBg } from 'src/styles/exports.module.scss';

export const TIER_TYPE_TO_VARIANT_CONFIG = {
  Core: {
    tierId: 'core',
    themeId: 'corePdpEvo',
    backgroundColor: colorSlate95,
  },
  Premium: {
    tierId: 'premium',
    themeId: 'premiumPdpEvo',
    backgroundColor: colorLinenBg,
  },
  Generation: {
    tierId: 'generation',
    themeId: 'generationPdpEvo',
    backgroundColor: colorMidnightBg,
  },
};
