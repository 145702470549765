import React, { useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/gatsby';
import { RefreshCell, RefreshGrid, GRID_DEFAULT_CONFIG, Subsection } from 'src/components/layout';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { FluidText } from 'src/components/common/FluidText';
import { fetchMortgageRates } from 'src/services/mortgage-rate-service';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { classNames, REFRESH_BREAKPOINTS } from 'src/utils/css';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { translations } from './MortgageRebateV2.translations';
import { BreakdownTable } from './components/BreakdownTable';
import { useMortgageCalculatorData } from './components/CalculatorProvider/CalculatorProvider.component';
import { THEMES, MORTGAGE_TYPE_TAB_LABELS, processTermData, TERM_DURATION_DEFAULT, TERM_TYPE_FIXED, getTermId, getTermById } from './MortgageRebateV2.helpers';
import { calculator_, wrapper_, subheading_, breakdownTable_ } from './MortgageRebateV2.module.scss';
import { CalculatorInputs } from './components/CalculatorInputs/CalculatorInputs.component';

const GENERIC_ERROR = 'We ran into some trouble loading the rates. Refresh the page and try again.';

export const MortgageRebateV2 = ({ heading = `Mortgage calculator`, subheading = `Estimate your low rate and monthly payments through Pine. Then, see how much you could save in rebates from us.`, subsectionProps }) => {
  const { appendTranslationKeys, locale } = useTranslationContext();
  appendTranslationKeys(translations);

  const { state, dispatch } = useMortgageCalculatorData();

  const isLargeBreakpoint = useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.lg}px)`);
  const isExtraSmallBreakpoint = !useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.sm}px)`);
  const isTabSelectorFullWidth = isExtraSmallBreakpoint || isLargeBreakpoint;
  const termAmoritizationPaymentFrequencyCellWidthDesktop = MORTGAGE_TYPE_TAB_LABELS[0] === state.mortgageType ? 4 : 3;
  const inputSize = isLargeBreakpoint ? 'lg' : 'sm';

  const wrapperRef = useRef();
  const tabSelectorMortgageTypeRef = useRef();
  const tabSelectorClientStatusRef = useRef();
  const depositSliderRef = useRef();
  const breakdownTableRef = useRef();

  useEffect(() => {
    fetchMortgageRates().then(data => {
      const _data_ = JSON.parse(data);
      const termDataInsured = processTermData(_data_.insured);
      const termDataUninsured = processTermData(_data_.uninsured);

      if (termDataInsured?.length === 0 && termDataUninsured?.length === 0) {
        dispatch({ type: 'SET_REQUEST_ERROR', payload: GENERIC_ERROR });
        Sentry.captureException(`Fetch mortgages error: the payload is empty or does not contain the expected data: ${data}`);
      }

      // insured terms are the default view
      const selectedTerm = getTermById(termDataInsured, getTermId(TERM_DURATION_DEFAULT, TERM_TYPE_FIXED));

      dispatch({ type: 'SET_INTEREST_RATE', payload: selectedTerm?.['data-rate'] });
      dispatch({ type: 'SET_TERM_DURATION', payload: selectedTerm?.['data-duration'] });
      dispatch({ type: 'SET_TERM_VALUE', payload: selectedTerm.value });
      dispatch({ type: 'SET_TERMS', payload: termDataInsured });
      dispatch({
        type: 'SET_ALL_TERM_DATA',
        payload: {
          insured: termDataInsured,
          uninsured: termDataUninsured,
        },
      });
      dispatch({ type: 'SET_HAS_MOUNTED', payload: true });
    }).catch(error => {
      dispatch({ type: 'SET_REQUEST_ERROR', payload: GENERIC_ERROR });
      Sentry.captureException(`Error fetching mortgage rates: ${error}`);
    });
  }, [dispatch]);

  useLayoutEffect(() => {
    gsap.registerPlugin(CustomEase);
    CustomEase.create('calc-ease', '0.33, 0.00, 0.67, 1.00');
  }, []);

  useLayoutEffect(() => {
    if (!state.hasMounted) return () => undefined;

    const tl = gsap.timeline({ repeat: 0 });
    const tier = state.tierLevel === 'non-client' ? 'core' : state.tierLevel;
    const duration = 0.4;
    const delay = 0;
    const ease = 'calc-ease';
    tl.to(document.documentElement, { duration, ...THEMES[tier].root, ease }, delay);
    tl.to(wrapperRef.current, { duration, ...THEMES[tier].wrapper, ease }, delay);
    tl.to([tabSelectorMortgageTypeRef.current, tabSelectorClientStatusRef.current], { duration, ...THEMES[tier].tabSelectors, ease }, delay);
    tl.to(depositSliderRef.current, { duration, ...THEMES[tier].slider, ease }, delay);
    tl.to(breakdownTableRef.current, { duration, ...THEMES[tier].breakdownTable, ease }, delay);

    return () => tl.kill();
  }, [state.tierLevel, state.hasMounted]);

  return (
    <Subsection className={classNames('overflow-x:clip', calculator_)} subsectionName="MortgageRebateV2" {...subsectionProps}>
      <div ref={wrapperRef} className={wrapper_}>
        <RefreshGrid
          all={{ columns: 2, columnGap: 16, rowGap: 32 }}
          lg={{ columns: 12, gap: GRID_DEFAULT_CONFIG.gap }}
        >
          <RefreshCell all={2} lg={6}>
            <FluidText type="h2" min="ws-display-md-sans" max="ws-display-xl-sans">{heading}</FluidText>
            <FluidText className={subheading_} type="markdown">{subheading}</FluidText>
          </RefreshCell>
          {state.hasMounted && (
            <CalculatorInputs
              locale={locale}
              inputSize={inputSize}
              isTabSelectorFullWidth={isTabSelectorFullWidth}
              termAmoritizationPaymentFrequencyCellWidthDesktop={termAmoritizationPaymentFrequencyCellWidthDesktop}
              tabSelectorMortgageTypeRef={tabSelectorMortgageTypeRef}
              tabSelectorClientStatusRef={tabSelectorClientStatusRef}
              depositSliderRef={depositSliderRef}
            />
          )}
        </RefreshGrid>
        {(!state.hasMounted || state.requestError) && (
          <div style={{ margin: `${GRID_DEFAULT_CONFIG.gap} 0`, height: '557px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!state.hasMounted && !state.requestError && (<div>LOADING RATES</div>)}
            {state.requestError && (<div>{state.requestError}</div>)}
          </div>
        )}
        {state.hasMounted && (
          <BreakdownTable
            ref={breakdownTableRef}
            className={breakdownTable_}
            depositValue={state.depositValue}
            termDuration={state.termDuration}
            interestRate={Number(state.interestRate)}
            mortgageAmount={state.mortgageAmount}
            amortizationPeriod={state.amortizationPeriod}
            paymentFrequency={state.paymentFrequency}
            depositRebate={state.depositRebate}
            clientStatusRebate={state.clientStatusRebate}
            tierLevel={state.tierLevel}
            style={{ margin: `${GRID_DEFAULT_CONFIG.gap} 0` }}
          />
        )}
        <RefreshGrid>
          <RefreshCell lg={{ width: 6 }}>
            <FluidText type="markdown" all="ws-text-xs">{useTranslation('rebate-calculator-v2::disclaimer')}</FluidText>
          </RefreshCell>
        </RefreshGrid>
      </div>
    </Subsection>
  );
};

MortgageRebateV2.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
};
