import React, { useState, useEffect, forwardRef } from 'react';
import { classNames } from 'src/utils/css';
import { useBreakpoints } from 'src/hooks/useBreakpoints';
import { formatCurrency } from 'src/utils/formatters';
import { FluidText } from 'src/components/common/FluidText';
import {
  innerRail_,
  rail_,
  controlWrapper_,
  control_,
  wrapper_,
  inputWrapper_,
  input_,
  initialControlWidth_,
  mobileWrapper_,
  desktopWrapper_,
} from './CalculatorSlider.module.scss';

const MIN_VALUE = 0;
const MAX_VALUE = 5000000;
const STEP_AMOUNT = 50000;

const CalculatorSlider = forwardRef(function CalculatorSlider({ value, onChange }, ref) {
  const [isDesktop, setIsDesktop] = useState(false);
  const { isLg, isXl } = useBreakpoints();

  useEffect(() => {
    const checkIfDesktop = isLg || isXl;
    setIsDesktop(checkIfDesktop);
  }, [isLg, isXl]);

  const initialControlWidth = value === 0;

  // Slider thumb width
  const SLIDER_WIDTH_MAX_PX_NO_UNITS = isDesktop ? '130' : '28'; // these units are derived from the rendered thumb at the largest value ($5,000,000)
  const SLIDER_WIDTH_MIN_PX_NO_UNITS = isDesktop ? '60' : '28'; // these units are derived from the rendered thumb at the smallest value ($0)

  // how far along the slider the thumb should be as a decimal
  const sliderPositionDecimal = (value / MAX_VALUE);

  // how far along the slider the thumb should be as a percentage, eg 86%
  const sliderPositionPercentString = `${Math.floor(sliderPositionDecimal * 100)}%`;

  // We must scale the position of the thumb so that it completely touches the left edge of the slider when the value is 0 (so just 0%)
  // but then the far side touches the right at 100%. To do this, we create an offset that at 0 has a value of 0, and at 100% has a value of the thumb width.
  const sliderThumbOffsetPx = `${Math.floor(SLIDER_WIDTH_MAX_PX_NO_UNITS * sliderPositionDecimal)}px`;
  const sliderThumbPositionString = `calc(${sliderPositionPercentString} - ${sliderThumbOffsetPx})`;

  // The right rail is 0% positioned on the left, but at the right will need to be the inverse of the thumb position
  const sliderInnerRailRightPositionString = `calc(100% - ${sliderPositionPercentString} + ${sliderThumbOffsetPx} - ${SLIDER_WIDTH_MIN_PX_NO_UNITS / 2}px)`;
  return (
    <div ref={ref} className={wrapper_}>
      <div className={inputWrapper_}>
        <input
          className={input_}
          type="range"
          value={value}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={STEP_AMOUNT}
          onChange={onChange}
        />
      </div>
      <div className={controlWrapper_}>
        <div className={desktopWrapper_}>
          <div className={classNames(control_, initialControlWidth && initialControlWidth_)} style={{ left: sliderThumbPositionString }}>
            <FluidText type="p" min="ws-text-sm-medium" max="ws-text-xl-medium">
              {formatCurrency(value, { precision: 0 })}
            </FluidText>
          </div>
        </div>
        <div className={mobileWrapper_}>
          <div className={control_} style={{ left: sliderThumbPositionString, width: `28px`, height: `28px` }} />
        </div>
        <div className={rail_}>
          <div
            className={innerRail_}
            style={{ left: `0%`, right: sliderInnerRailRightPositionString }}
          />
        </div>
      </div>
    </div>
  );
});

export default CalculatorSlider;
