export const translations = {
  'en-ca': {
    'eyebrow::tiers-thresholds::core': `{{AUM_THRESHOLD_CORE}} in assets`,
    'eyebrow::tiers-thresholds::premium': `{{AUM_THRESHOLD_PREMIUM}} in assets`,
    'eyebrow::tiers-thresholds::generation': `{{AUM_THRESHOLD_GENERATION}} in assets`,
  },
  'fr-ca': {
    'eyebrow::tiers-thresholds::core': `{{AUM_THRESHOLD_CORE}} d’actifs`,
    'eyebrow::tiers-thresholds::premium': `{{AUM_THRESHOLD_PREMIUM}} d’actifs`,
    'eyebrow::tiers-thresholds::generation': `{{AUM_THRESHOLD_GENERATION}} d’actifs`,
  },
};
