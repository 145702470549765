import { formatCurrency, formatDecimalToPercentage } from 'src/utils/formatters';
import { CLIENT_STATUS_TAB_LABELS, AMORITIZATION_DURATIONS_IN_MONTHS, MORTGAGE_TYPE_TAB_LABELS, PAYMENT_FREQUENCY_OPTIONS, DEFAULT_MORTGAGE_AMOUNT, DEFAULT_DOWN_PAYMENT_AMOUNT, DEFAULT_DOWN_PAYMENT_PERCENT, DEFAULT_CLIENT_DEPOSIT_REBATE, DEFAULT_CLIENT_STATUS_REBATE, TERM_DURATION_DEFAULT } from '../../MortgageRebateV2.helpers';

const locale = 'en-CA';

const DEFAULT_MORTGAGE_TYPE_INDEX = 1;
const DEFAULT_MORTGAGE_TYPE = MORTGAGE_TYPE_TAB_LABELS[DEFAULT_MORTGAGE_TYPE_INDEX];
const DEFAULT_AMORTIZATION_INDEX = 15;
const DEFAULT_PAYMENT_FREQUENCY_INDEX = 0;

export const initialState = {
  requestError: null,
  depositValue: DEFAULT_CLIENT_DEPOSIT_REBATE,
  depositRebate: DEFAULT_CLIENT_DEPOSIT_REBATE,
  terms: [{}],
  allTermData: { insured: [], uninsured: [] },
  termLength: TERM_DURATION_DEFAULT,
  termValue: `${TERM_DURATION_DEFAULT}-fixed`,
  interestRate: null,
  mortgageAmount: DEFAULT_MORTGAGE_AMOUNT,
  propertyPrice: DEFAULT_MORTGAGE_AMOUNT,
  downPaymentPercentValue: formatDecimalToPercentage(DEFAULT_DOWN_PAYMENT_PERCENT / 100, { locale }),
  downPaymentPercent: DEFAULT_DOWN_PAYMENT_PERCENT,
  downPaymentDollar: formatCurrency(DEFAULT_DOWN_PAYMENT_AMOUNT, { locale }),
  tierLevel: CLIENT_STATUS_TAB_LABELS[0].toLowerCase(),
  clientStatusRebate: DEFAULT_CLIENT_STATUS_REBATE,
  amortizationPeriod: AMORITIZATION_DURATIONS_IN_MONTHS[DEFAULT_AMORTIZATION_INDEX],
  paymentFrequency: PAYMENT_FREQUENCY_OPTIONS[DEFAULT_PAYMENT_FREQUENCY_INDEX].value,
  mortgageType: DEFAULT_MORTGAGE_TYPE,
  hasMounted: false,
};

export const calculatorReducer = (state, action) => {
  switch (action.type) {
    case 'SET_REQUEST_ERROR':
      return { ...state, requestError: action.payload };
    case 'SET_TERMS':
      return { ...state, terms: action.payload };
    case 'SET_TERM_DURATION':
      return { ...state, termDuration: action.payload };
    case 'SET_TERM_VALUE':
      return { ...state, termValue: action.payload };
    case 'SET_ALL_TERM_DATA':
      return { ...state, allTermData: action.payload };
    case 'SET_INTEREST_RATE':
      return { ...state, interestRate: action.payload };
    case 'SET_HAS_MOUNTED':
      return { ...state, hasMounted: action.payload };
    case 'SET_MORTGAGE_TYPE':
      return { ...state, mortgageType: action.payload };
    case 'SET_PROPERTY_PRICE':
      return { ...state, propertyPrice: action.payload };
    case 'SET_DOWN_PAYMENT_PERCENT':
      return { ...state, downPaymentPercent: action.payload };
    case 'SET_DOWN_PAYMENT_DOLLAR':
      return { ...state, downPaymentDollar: action.payload };
    case 'SET_MORTGAGE_AMOUNT':
      return { ...state, mortgageAmount: action.payload };
    case 'SET_AMORTIZATION_PERIOD':
      return { ...state, amortizationPeriod: action.payload };
    case 'SET_PAYMENT_FREQUENCY':
      return { ...state, paymentFrequency: action.payload };
    case 'SET_TIER_LEVEL':
      return { ...state, tierLevel: action.payload };
    case 'SET_CLIENT_STATUS_REBATE':
      return { ...state, clientStatusRebate: action.payload };
    case 'SET_DEPOSIT_VALUE':
      return { ...state, depositValue: action.payload };
    case 'SET_DEPOSIT_REBATE':
      return { ...state, depositRebate: action.payload };
    default:
      return state;
  }
};
