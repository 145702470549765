import React from 'react';
import { getFAQ, getFAQGatsby } from 'src/utils/contentful';
import { FAQ } from 'src/components/subsections/FAQ';
import { withFramework } from 'lib/get-framework';

export const ContentfulFaq = ({ faqHeading, faqQuestions, entryId, ...subsectionProps }) => {
  const faqs = withFramework({
    gatsby: () => getFAQGatsby({
      entryId,
      heading: faqHeading,
      questions: faqQuestions,
    }),
    next: () => getFAQ({
      entryId,
      heading: faqHeading,
      questions: faqQuestions,
    }),
  });

  return faqs && <FAQ {...subsectionProps} entryId={entryId} {...faqs} />;
};
