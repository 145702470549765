import React from 'react';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { asSubsection, RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from 'src/components/layout';
import { FeatureCard } from 'src/components/common/FeatureCard';
import { REFRESH_BREAKPOINTS } from 'src/utils/css';

const MAX_CELL_WIDTH = 12;

// When the card is full width at large and xl screens, we pass a prop to limit the width of the child text
// for readability purposes at larger screens
const getShouldLimitTextWidth = cellWidths => {
  const breakpointsDescending = Object.keys(REFRESH_BREAKPOINTS).reverse();
  const maxCardSize = breakpointsDescending.reduce((acc, breakpoint) => {
    if (acc) {
      return acc;
    }
    if (cellWidths?.[breakpoint]?.width) {
      return cellWidths[breakpoint].width;
    }
    if (cellWidths?.[breakpoint]) {
      return cellWidths[breakpoint];
    }
    return null;
  }, null);

  return maxCardSize === MAX_CELL_WIDTH;
};

const FeatureCardGrid = asSubsection('FeatureCardGrid')(({ cards = [], id, config, ...rest }) => {
  const _config = merge(
    { xs: { columnGap: 0 }, lg: { columnGap: GRID_DEFAULT_CONFIG.gap } },
    config
  );
  return (
    <div>
      <RefreshGrid {..._config} {...rest}>
        {cards.map((card, index) => {
          const cellId = `${id}-${index}`;

          // Allow react element to be passed as the card
          if (React.isValidElement(card)) {
            const { cellWidths = { sm: 6 }, shouldLimitTextWidth } = card.props || {};
            return (
              <RefreshCell key={cellId} {...cellWidths}>
                {React.cloneElement(card, {
                  shouldLimitTextWidth: shouldLimitTextWidth ?? getShouldLimitTextWidth(cellWidths),
                })}
              </RefreshCell>
            );
          }

          // Otherwise, treat card as an object and use FeatureCard component
          const { children, cellWidths = { sm: 6 }, shouldLimitTextWidth, ...cardProps } = card;
          return (
            <RefreshCell key={cellId} {...cellWidths}>
              <FeatureCard
                {...cardProps}
                shouldLimitTextWidth={shouldLimitTextWidth ?? getShouldLimitTextWidth(cellWidths)}
              >
                {children}
              </FeatureCard>
            </RefreshCell>
          );
        })}
      </RefreshGrid>
    </div>
  );
});

FeatureCardGrid.propTypes = {
  id: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape()),
  config: PropTypes.shape(),
};

FeatureCardGrid.displayName = 'FeatureCardGrid';

export { FeatureCardGrid };
