import React from 'react';
import { classNames } from 'src/utils/css';
import { wrapper_, table_ } from './Table.module.scss';

/* When using markdown, the table will be given a `columnAlignment` prop, which is an array like ['center', 'left', null], where each entry is the alignment for a column.
 * We currently ignore this - so destructure it and do not pass it down.
 */
export const Table = (
  { children, className, columnAlignment, ...rest } // eslint-disable-line no-unused-vars
) => (
  <div className={classNames(className, wrapper_)} {...rest}>
    <table className={table_}>{children}</table>
  </div>
);

Table.propTypes = {};
